<template>
  <div class="home" v-loading="loadCode"
	element-loading-text="拼命上传中,(*^▽^*)">
    <h1<el-button @click="logout">一键退出</el-button></h1>
    <hr />
	<div>
		<el-upload
		      class="upload-demo"
		      drag
		      :action="uploadUrl"  
		      :on-success="handleSuccess"
			  :headers="headers"
		      :before-upload="beforeUpload1" 
		      :multiple="false"
		    >
		  <i class="el-icon-upload"></i>
		  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		   <div class="el-upload__text"><h2>软件测试第1次作业</h2></em></div>
		  <div class="el-upload__tip" slot="tip">只能上传doc/docx文件，且不超过5MB</div>
		</el-upload>
	</div>
	<div>
		<el-upload
		      class="upload-demo"
		      drag
		      :action="uploadUrl"  
		      :on-success="handleSuccess"
			  :headers="headers"
		      :before-upload="beforeUpload2" 
		      :multiple="false"
		    >
		  <i class="el-icon-upload"></i>
		  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		   <div class="el-upload__text"><h2>软件测试第2次作业</h2></em></div>
		  <div class="el-upload__tip" slot="tip">只能上传doc/docx文件，且不超过5MB</div>
		</el-upload>
	</div>
	<div>
		<el-upload
		      class="upload-demo"
		      drag
		      :action="uploadUrl"  
		      :on-success="handleSuccess"
			  :headers="headers"
		      :before-upload="beforeUpload3" 
		      :multiple="false"
		    >
		  <i class="el-icon-upload"></i>
		  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		   <div class="el-upload__text"><h2>软件测试第3次作业</h2></em></div>
		  <div class="el-upload__tip" slot="tip">只能上传doc/docx文件，且不超过5MB</div>
		</el-upload>
	</div>
	<div
	>
		<el-upload
		      class="upload-demo"
		      drag
		      :action="uploadUrl"  
		      :on-success="handleSuccess"
			  :headers="headers"
		      :before-upload="beforeUpload4" 
		      :multiple="false"
		    >
		  <i class="el-icon-upload"></i>
		  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		   <div class="el-upload__text"><h2>软件测试第4次作业</h2></em></div>
		  <div class="el-upload__tip" slot="tip">只能上传doc/docx文件，且不超过5MB</div>
		</el-upload>
	</div>
	
	<!-- <div
	v-loading="loadCode5"
	element-loading-text="拼命上传中,(*^▽^*)">
		<el-upload
		      class="upload-demo"
		      drag
		      :action="uploadUrl"  
		      :on-success="handleSuccess"
			  :headers="headers"
		      :before-upload="beforeUpload5" 
		      :multiple="false"
		    >
		  <i class="el-icon-upload"></i>
		  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		   <div class="el-upload__text"><h2 style="color: red;">更新急速上传通道--期末作业</h2></em></div>
		  <div class="el-upload__tip" slot="tip">只能上传zip/rar/7z文件，且不超过20MB</div>
		</el-upload>
	</div> -->
  </div>
</template>

<script>

export default {

	data(){
		return {
			uploadUrl: `${window.baseApi}/api/file/v1/upload`,
			headers:{
				Authorization: localStorage.getItem('token')
			},
			loadCode: false,
	};},
  methods:{
    async logout(){
      let myid = sessionStorage.getItem('myid') || 'a';
      let rel = await this.$axios.get(`${window.baseApi}/api/web/v1/Logout?myid=${myid}`)
      console.log('登出：',rel);
      if(rel && rel.data){
		this.$store.commit('setToken', "");
        this.$router.push('/')
      } else {
        // 如果扫码了，跳转页面
        this.$message({
          message: '服务器异常',
          type: "error"
        })
      }
    },
	setLoadCode1(newState) {
	  this.loadCode1 = newState;
	},
	handleSuccess(response, file, fileList) {
	      // 处理上传成功的逻辑
	      // console.log(response);
	    },
	beforeUpload1(file) {
		this.loadCode = true;
	      // 上传前的校验函数
	      const isAllowedFileType = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
	      const isAllowedFileSize = file.size < 5 * 1024 * 1024;  // 5MB
	
	      if (!isAllowedFileType) {
	        this.$message.error('只能上传doc/docx文件');
			const self = this
			self.loadCode = false;
	        return false;
	      }
	
	      if (!isAllowedFileSize) {
	        this.$message.error('文件大小不能超过5MB');
			const self = this
			self.loadCode = false;
	        return false;
	      }
	
	      // 构建 FormData 对象，用于传递文件和参数
	      const formData = new FormData();
	      formData.append('file', file);  // 上传的文件
	      formData.append('type', 1);  // 传入的整数参数
	
	      // 发起 POST 请求
	      this.$axios.post(this.uploadUrl, formData,{
			  headers:{
				   token: localStorage.getItem('token'),
			  }
		  })
	        .then(response => {
				this.loadCode = false;
	          if(response.data.code === "200"){
				   this.$message({
				     message: '上传成功！',
				     type: "success"
				   })
				   
			  }else{
				 this.$message({
				   message: response.data.msg,
				   type: "error"
				 })
			  }
			  
	        })
	        .catch(error => {
				
	          console.error(error);
	        });
			
	      return false;  // 终止默认的文件上传行为
	    },	
		
		// 2
		beforeUpload2(file) {
			this.loadCode = true;
			
		      // 上传前的校验函数
		      const isAllowedFileType = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		      const isAllowedFileSize = file.size < 5 * 1024 * 1024;  // 5MB
		
		      if (!isAllowedFileType) {
		        this.$message.error('只能上传doc/docx文件');
				const self = this
				self.loadCode = false;
		        return false;
		      }
		
		      if (!isAllowedFileSize) {
		        this.$message.error('文件大小不能超过5MB');
				const self = this
				self.loadCode = false;
		        return false;
		      }
		
		      // 构建 FormData 对象，用于传递文件和参数
		      const formData = new FormData();
		      formData.append('file', file);  // 上传的文件
		      formData.append('type', 2);  // 传入的整数参数
		
		      // 发起 POST 请求
		      this.$axios.post(this.uploadUrl, formData,{
				  headers:{
					   token: localStorage.getItem('token'),
				  }
			  })
		        .then(response => {
					this.loadCode = false;
		          if(response.data.code === "200"){
					   this.$message({
					     message: '上传成功！',
					     type: "success"
					   })
				  }else{
					 this.$message({
					   message: response.data.msg,
					   type: "error"
					 })
					
				  }
				  
		        })
		        .catch(error => {
		          console.error(error);
				 
		        });
		      return false;  // 终止默认的文件上传行为
		    },
			// 3
			beforeUpload3(file) {
				this.loadCode = true;
			      // 上传前的校验函数
			      const isAllowedFileType = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
			      const isAllowedFileSize = file.size < 5 * 1024 * 1024;  // 5MB
			
			      if (!isAllowedFileType) {
			        this.$message.error('只能上传doc/docx文件');
					const self = this
					self.loadCode = false;
			        return false;
			      }
			
			      if (!isAllowedFileSize) {
			        this.$message.error('文件大小不能超过5MB');
					const self = this
					self.loadCode = false;
			        return false;
			      }
			
			      // 构建 FormData 对象，用于传递文件和参数
			      const formData = new FormData();
			      formData.append('file', file);  // 上传的文件
			      formData.append('type', 3);  // 传入的整数参数
			
			      // 发起 POST 请求
			      this.$axios.post(this.uploadUrl, formData,{
					  headers:{
						   token: localStorage.getItem('token'),
					  }
				  })
			        .then(response => {
						this.loadCode = false;
			          if(response.data.code === "200"){
						   this.$message({
						     message: '上传成功！',
						     type: "success"
						   })
						   
					  }else{
						 this.$message({
						   message: response.data.msg,
						   type: "error"
						 })
					  }
					  
			        })
			        .catch(error => {
			          console.error(error);
			        });
			      return false;  // 终止默认的文件上传行为
			    },
				
				// 4
				beforeUpload4(file) {
					this.loadCode = true;
				      // 上传前的校验函数
				      const isAllowedFileType = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
				      const isAllowedFileSize = file.size < 5 * 1024 * 1024;  // 5MB
				
				      if (!isAllowedFileType) {
				        this.$message.error('只能上传doc/docx文件捏');
						const self = this
						self.loadCode = false;
				        return false;
				      }
				
				      if (!isAllowedFileSize) {
				        this.$message.error('文件大小不能超过5MB');
						const self = this
						self.loadCode = false;
				        return false;
				      }
				
				      // 构建 FormData 对象，用于传递文件和参数
				      const formData = new FormData();
				      formData.append('file', file);  // 上传的文件
				      formData.append('type', 4);  // 传入的整数参数
				
				      // 发起 POST 请求
				      this.$axios.post(this.uploadUrl, formData,{
						  headers:{
							   token: localStorage.getItem('token'),
						  }
					  })
				        .then(response => {
							this.loadCode = false;	
				          if(response.data.code === "200"){
							   this.$message({
							     message: '上传成功！',
							     type: "success"
							   })
						  }else{
							 this.$message({
							   message: response.data.msg,
							   type: "error"
							 })
						  }
						  
				        })
				        .catch(error => {
				          console.error(error);
				        });
					
				      return false;  // 终止默认的文件上传行为
				    },
					// 5
					beforeUpload5(file) {
						 this.loadCode5 = true;
					      // 构建 FormData 对象，用于传递文件和参数
					      const formData = new FormData();
					      formData.append('file', file);  // 上传的文件
					      formData.append('type', 5);  // 传入的整数参数
					      // 发起 POST 请求
					      this.$axios.post(this.uploadUrl, formData,{
							  headers:{
								   token: localStorage.getItem('token'),
							  },
						  })
					        .then(response => {
								this.loadCode5 = false;
					          if(response.data.code === "200"){
								   this.$message({
								     message: '上传成功！',
								     type: "success"
								   })
								
							  }else{
								 this.$message({	
								   message: response.data.msg,
								   type: "error"
								 })
							  }
							  
					        })
					        .catch(error => {
					          console.error(error);
					        });
							
					      return false;  // 终止默认的文件上传行为
					    },
  },
  
  
}
</script>
