<template>
  <div>
    <h2>SocialShere</h2>
	<p style="font-size: 12px;">内部使用</p>
	<div>微信扫码登录</div>
    <div class="qrCode" 
      v-loading="loadCode"
      element-loading-text="拼命加载中">
        <iframe class="wh100 box" id="frame" src="./qrCode.html" scrolling="no" ></iframe>
    </div>
	<p>扫码关注公众号『 一只能猫开发者 』登录</p>
	<p style="font-size: 12px;">注册登录即表示同意 <a style="color: #4183C4;" href="./user.html">用户协议</a> 和 <a style="color: #4183C4;" href="./yinsi.html">隐私政策</a></p>
  </div>
</template>

<script>

export default {
  data(){
    return {
      loadCode: true,
    }
  },
  created(){
    let timer = setTimeout(()=>{
      this.loadCode = false;
      clearTimeout(timer);
    }, 2000)

    // 轮询后台，看用户是否扫码成功
    this.loopFun()
  },
  methods:{
    sleep(dealy){
      return new Promise((res, rej)=>{
        let tm = setTimeout(()=>{
          clearTimeout(tm)
          res()
        }, dealy)
      })
    },
    async loopFun(){
      // 根据本地存储的myid区分用户,在生成二维码的时候存储的，也传给后台了
      let myid = sessionStorage.getItem('myid') || 'a'
      console.log('myid', myid);
      let rel = await this.$axios.get(`${window.baseApi}/api/web/v1/Login?myid=${myid}`)
      console.log('是否登录：',rel);
      
      if(rel && rel.data.json==true){
        this.$message({
          message: '登录成功',
          type: "success"
        })
        this.$router.push('/home')
        return
      }

      // 如果还没登录，休眠3s再问一次
      if(!rel || (rel && !rel.data.json) || !rel.data.json.login){
        // 休眠3s
        await this.sleep(3000)
		// await this.sleep(15000)
        this.loopFun()
      } else {
		// 调用Vuex的mutations来存储token
		const token = rel.data.token;
		  this.$store.commit('setToken', token);
        // 如果扫码了，跳转页面
        // 这里搭配java版本的
        this.$message({
          message: '登录成功',
          type: "success"
        })
        this.$router.push('/home')
      }
    }
  }

}
</script>
<style>

  #frame { 
    width: 450px; height: 450px; 
    position:relative; left:-8px; top: -8px;
    -webkit-transform-origin: 0 0;
    -webkit-transform: scale(0.5);
  }
</style>

<style scoped>
  .wh100{
    width: 100%;
    height: 100%;
  }
  
  .box{
    border: none;
    overflow: hidden;
  }
  .qrCode{
    border: 1px solid #ddd;
    width: 200px;
    height: 200px;
    margin: 20px auto;
    overflow: hidden;
  }
</style>